<template>
  <v-container>

    <v-row class="text-center mt-4">
      <v-col cols="12">
              
          <v-row no-gutters>
            <v-col class="text-left" >

              <a href="http://www.testserver.ee/nii/admin.PNG" target="_blank" rel="noopener noreferrer">Admin ülevaade</a>
            </v-col>
            <v-col class="text-right" >
              <v-btn  depressed class="mt-2">Jaga linki</v-btn> 
            </v-col>
          </v-row>
            
          <template>
          

            <v-card  class="mt-8">

              <!-- https://vuetifyjs.com/en/components/navigation-drawers/ -->      
              <!-- absolute  -->
            
              <v-row>
                <v-col cols="4" style="border-right: 1px solid  rgba(0, 0, 0, 0.12);">


      <v-card class="d-flex flex-column fill-height">

          <div>
                    <v-container class="text-left">
                      <v-row>
                        <v-col>
                                    <v-select
                                      v-model="userData.address"
                                      :items="options.addresses"
                                      label="Aadress"
                                    ></v-select>
                        </v-col>
                      </v-row>
                      <v-row no-gutters>
                        <v-col>
                                    <v-select
                                      v-model="userData.apartment"  
                                      :items="options.apartments"
                                      label="Korter"
                                    ></v-select>
                        </v-col>
                      </v-row>
                      <v-row  >
                        <v-col>

                            <h3>SINU PAKETT:</h3>
                            <p>Valitud pakett: <strong>{{userData.package}}</strong></p> 
                            <p>Soovid kohandada paketti: <strong>{{userData.configureWishPackage}}</strong></p>

                            <br>
                            <h3>EHITUS:</h3>
                            <p>Soovid kohandada ehitust: <strong>{{userData.configureWishConstruction}}</strong></p>
                        </v-col>
                      </v-row>
                    
                    </v-container>
        </div>
        <v-spacer></v-spacer>
        <v-card-actions>

          <div style="width:100%">
                      <v-btn block depressed class="mt-2" color="green">Salvesta valikud</v-btn> 
                      <v-btn block depressed class="grey lighten-2 mt-2" color="info">Kinnita PAKETT</v-btn>
                      <v-btn block depressed class="grey lighten-2 mt-2" color="info">Kinnita MUUDATUS</v-btn>
          </div>
                   
        </v-card-actions>
      </v-card>


 

     

                </v-col>
                <v-col  cols="8">
 
                        <template>
                       
                    
                            <v-tabs  fixed-tabs >
                              <v-tab>
                                <v-icon left>
                                  mdi-floor-plan
                                </v-icon>
                                Plaanid
                              </v-tab>
                              <v-tab>
                                <v-icon left>
                                  mdi-palette
                                </v-icon>
                                Pakett
                              </v-tab>
                    

                              <v-tab-item>
                                <v-card flat style="border:none; box-shadow: none;" >
                                  <v-card-text>


                                    <v-container class="text-left">
                                      <v-row>
                                        <v-col>
                                                <h2>Sisearhidektuur</h2>
                                                <div class="pt-4">
                                                  <v-select v-model="userData.planList"
                                                      :items="planList"
                                                      label="Vali plaan"
                                                      outlined
                                                    ></v-select>   
                                                </div>

                                        </v-col>  
                                        <v-col>
                                                <h2>Soovid kohandada?</h2>
                                                <div class="pt-4">
                                                    <p>Korteri kohandamisega kaasneb menetlustasu 1000€</p>

                                                        <v-switch
                                                          v-model="userData.configureWishConstruction"
                                                          label="Soovin kohandada"
                                                          color="success"
                                                          value="Jah"
                                                          hide-details
                                                        ></v-switch>  
                                                        <div v-if="userData.configureWishConstruction == 'Jah'">
                                                          <p><i>Märgi oma soovid allolevale joonisele või täida lahtrid</i></p>
                                                        </div>                                                  
                                                </div> 

                                        </v-col>                                                
                                      </v-row>
                                      <v-row v-if="userData.configureWishConstruction =='Jah'">                                                                              
                                          <v-col>
                                            <v-row>
                                              <v-col>
                                                <div class="d-flex" style="justify-content: space-between;">
                                                  <div> 
                                                    <v-btn elevation="1" rounded icon  class="mr-2">
                                                      <v-icon>mdi-brush</v-icon>
                                                    </v-btn> 
                                                    <v-btn elevation="1" rounded icon  class="mr-2">
                                                      <v-icon>mdi-palette</v-icon>
                                                    </v-btn>  
                                                  </div> 
                          
                                                  <div class="pr-4">  
                                                    <v-btn elevation="1" rounded icon  class="mr-2 black" >
                                                      <v-icon> </v-icon>
                                                    </v-btn> 
                                                    <v-btn elevation="1" rounded icon  class="mr-2 red" >
                                                      <v-icon> </v-icon>
                                                    </v-btn> 
                                                    <v-btn elevation="1" rounded icon  class="mr-2 green" >
                                                      <v-icon> </v-icon>
                                                    </v-btn> 
                                                    <v-btn elevation="1" rounded icon  class="mr-2 blue" >
                                                      <v-icon> </v-icon>
                                                    </v-btn> 
                                                  </div> 
                                              </div>                                                 
                                              </v-col>
                                            </v-row>
 

                                            <v-row>
                                                <v-col>
                                                    <DrawMoodul   canvasid="client_drawing" />  
                                                </v-col>
                                            </v-row>
                                              
                                            <v-row>
                                              <v-col>
                                                <h3 class="pb-2">Muudatuse täpsustused</h3>

                                                <div v-for="(item, index) in userData.notes" :key="index">
                                                  <v-row>
                                                      <v-col  cols="9">
                                                          <v-textarea v-model="item.text"
                                                            outlined
                                                            label="Märkused"
                                                            placeholder="Täpsusta soovi"
                                                          ></v-textarea>                                                  
                                                      </v-col>                                        
                                                      <v-col cols="3">
                                                        <div>
                                                        <v-select v-model="item.category"
                                                            :items="planList"
                                                            label="Kategooria"
                                                            outlined
                                                          ></v-select>                                                    
                                                        </div>
                                                        <div>
                                                          <v-file-input v-model="item.file"
                                                            label="Lisa fail"
                                                            multiple
                                                            outlined
                                                            truncate-length="15"
                                                          ></v-file-input>
                                                        </div>
                                                      </v-col>                                        
                                                  </v-row>
                                                </div>
                                  
                                                <v-row>
                                                  <v-col>   
                                                    <v-btn depressed @click="addNotesRow">Lisa rida</v-btn>
                                                  </v-col>                                        
                                                </v-row>
                                  
                                              </v-col>                                        
                                            </v-row>   

                                          </v-col>           
                                      </v-row>
                                      <v-row v-else>
                                        <v-col>
                                          <v-alert
                                            outlined
                                            type="warning"
                                          >Kohandamiseks tee valik</v-alert>   
                                        </v-col>
                                      </v-row>

                  

                                    </v-container>

                                  
                                  </v-card-text>
                                </v-card>
                              </v-tab-item>
                              <v-tab-item>
                                <v-card flat>
                                  <v-card-text class="text-left">
      

                                    <v-container >
                                      <v-row>
                                        <v-col>
                                              <h2>Vali pakett</h2>
                                              
                                              <v-radio-group v-model="userData.package">
                                                  <v-radio value="Bold">
                                                    <template v-slot:label>
                                                      <div>Bold <strong class="success--text">---</strong></div>
                                                    </template>
                                                  </v-radio>
                                                  <v-radio value="Minimal">
                                                    <template v-slot:label>
                                                      <div>Minimal <strong class="success--text">---</strong></div>
                                                    </template>
                                                  </v-radio>
                                                  <v-radio value="Classy">
                                                    <template v-slot:label>
                                                      <div>Classy <strong class="success--text">---</strong></div>
                                                    </template>
                                                  </v-radio> 
                                              </v-radio-group> 

                                              <div class="interior-package-pdf ">
                                                <p><v-btn  depressed href="https://vektor.ee/images/Vektor-korterid-interior-2021_UUS.pdf" target="_blank" class="btn btn-primary">Pakettide PDF</v-btn></p>
                                              </div>

                                        </v-col>
                                        <v-col>
                                                <h2>Soovid kohandada</h2>
                                                <div>
                                                        <v-switch
                                                          v-model="userData.configureWishPackage"
                                                          label="Soovin paketti kohandada (lisandub tasu)"
                                                          color="success"
                                                          value="Jah"
                                                          hide-details
                                                        ></v-switch>
                                                </div>
                                        </v-col>
                                      </v-row>
                                      <v-row>
                                        <v-col v-if="userData.configureWishPackage == 'Jah'">

                                              <div class="mt-4 text-left">
                                                <h3>Vali mida soovid kohandada:</h3>
                                              </div>
                                              <div class="interior-package-details row m-0 ps-4 pe-2 px-lg-0"> 
                                                  <div class="col-6 col-md-3  pb-6">
                                                    <v-img
                                                      lazy-src="https://vektor.ee/images/bold-parkett.jpg"
                                                      max-height="150"
                                                      max-width="150"
                                                      src="https://vektor.ee/images/bold-parkett.jpg"
                                                    ></v-img> 
                                                    <p class="bold mt-1 mb-0">Parkett</p>
                                                    <p class="mb-0">Karelia tamm</p>
                                                    <p class="mb-0">Time Smoked matt lakk</p>
                                                  </div>
                                                  <div class="col-6 col-md-3  pb-6"> 
                                                    <v-img
                                                      lazy-src="https://vektor.ee/images/bold-siseuks.jpg"
                                                      max-height="150"
                                                      max-width="150"
                                                      src="https://vektor.ee/images/bold-siseuks.jpg"
                                                    ></v-img>            
                                                    <p class="bold mt-1 mb-0">Siseuksed</p>
                                                    <p class="mb-0">Sile spoonuks</p>
                                                  </div>
                                              </div>     
                                        </v-col>
                                      </v-row>
                                    </v-container>

  

   


                                  </v-card-text>
                                </v-card>
                              </v-tab-item>
                     
                            </v-tabs>
                         
                        </template>

                </v-col>
              </v-row>
 

  
              

                

                
 
 
            </v-card>
        
          </template>

 
 
      </v-col> 
    </v-row>
  </v-container>
</template>

<script>

  
  import DrawMoodul from './DrawMoodul';
  
  export default {
    name: 'MainTabs',


    components: {
      DrawMoodul
    },
    mounted(){
      this.addNotesRow()
    },

    methods: {
      addNotesRow: function(){
          this.userData.notes.push(this.noteRow)
      }
    },

    data: () => ({

        noteRow: {
          text: '',
          category: '',
          file: ''
        },
        planList: [
          'Sisearhidektuur',
          'Elekter',
          'Vesi ja kanalisatsioon',
          'Küte ja ventilatsioon' 
        ],
        options:{
          systems: ['elekter', 'vesi'],
          addresses: ['Pärnu mnt 107', 'Pärnu mnt 103'],
          apartments: ['Korter 17', 'Korter 19'],
          packageList: [
            "Bold", "Minimal", "Classy"
          ],          
        },
        userData:{ 
          address: "",
          apartment: "",
          package: "Bold",
          configureWishConstruction: "Ei",
          configureWishPackage: "Ei",
          notes: [],
          planList: "Sisearhidektuur"
        },




        drawer: null, 
    
        window: 0,

      

        items: [
          { title: 'Dashboard', icon: 'mdi-view-dashboard' },
          { title: 'Photos', icon: 'mdi-image' },
          { title: 'About', icon: 'mdi-help-box' },
        ],
        right: null,     
   
    }),
  }
</script>

<style scoped>
  .black { background-color: black;}
  .red { background-color: red;}
  .blue { background-color: blue;}
  .green { background-color: green;}
</style>