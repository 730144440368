<template>  
  <div class="signature text-left">   
    <!-- https://vektor.ee/images/apartment_plans/010.svg   https://testserver.ee/kardinad/korter2.png -->
    <canvas  
      v-bind:id="htmlID" width="680px"   height="600"    
      style="border: 1px solid gray;  background-image:url('https://vektor.ee/images/apartment_plans/010.svg'); background-size: contain; background-position: center center;"></canvas> 
   
    <div  class="d-flex" style="justify-content: space-between;">
      <div>
        <v-btn
          color="gray" 
          small 
          elevation="0"
          @click="clearSignature()"
        >Tühjenda joonis</v-btn>
      </div>
      <div class="pr-4">
        <v-btn
          color="green" 
          small 
          elevation="0"
          @click="clearSignature()"
        >Salvesta joonis</v-btn>        
      </div>
    </div>

  </div>
</template>

 
<script>

  //import _ from 'lodash';
  import SignaturePad from 'signature_pad';

  export default {

    props: ["canvasid"], 
    
    data: () => ({  
        htmlID: null,
        signaturePad: null,
        isLoading: false, 
        padOfSignarature: null,
    }),

    created(){
      this.htmlID = this.canvasid //signaturepad cant wait until HTML id is set
    },
    mounted(){  
 

        this.padOfSignarature = new SignaturePad(document.getElementById(this.htmlID));   

         
          window.addEventListener("resize", this.resizeCanvas());
       
 

          /*
          var can = document.getElementById(this.htmlID);
          var ctx = can.getContext('2d');

          //var svg = document.getElementById('mySVG');

          var img = new Image();
          img.onload = function() {
            ctx.drawImage(img, 0, 0);
          }
          //img.src = "http://upload.wikimedia.org/wikipedia/commons/d/d2/Svg_example_square.svg"; 
          img.src = "https://vektor.ee/images/apartment_plans/010.svg"; 
          */
 

    },
    
    methods: {
 
      
        clearSignature: function(){
          this.padOfSignarature.clear()
        },
        sendToParent: function(){
          //this.$emit("event_project", this.userSelectedProject); 
        },
 
        resizeCanvas: function(){  
            var canvas = document.getElementById(this.htmlID);
            var ratio =  Math.max(window.devicePixelRatio || 1, 1);
            canvas.width = canvas.offsetWidth * ratio;
            canvas.height = canvas.offsetHeight * ratio;
            canvas.getContext("2d").scale(ratio, ratio);
            this.padOfSignarature.clear(); // otherwise isEmpty() might return incorrect value
        },
 

        setSignature(){  
   
        } //end getProjectsFromCRM
      
    },

 

    watch: {  
      /*
      place: function () { //watch when PARENT changes  
        this.getProjectsFromCRM(this.place)
      },*/ 
    }   


  }
 
</script> 
